import React from 'react';
import { Button, Link } from '@nextui-org/react';
import Towers from "../assets/Towers";
import Arrow from "../assets/Arrow";
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';

const fadeInAnimation = {
  initial: { y: 10, opacity: 0 },
  whileInView: (duration) => ({ 
    y: 0, 
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: duration
    }
  }),
};

const Banner = () => {
  const { t } = useTranslation();

  return (
    <div className="h-[100vh] flex items-center ml-[60px] max-sm:ml-[20px]" id="home">
      <motion.div 
        initial="initial" 
        whileInView="whileInView" 
        viewport={{ once: true, amount: 0.4 }}
        className="w-[60%] max-md:w-[100%] z-10"
      >
        <motion.p 
          variants={fadeInAnimation} 
          custom={2} 
          className="font-extrabold text-7xl max-lg:text-4xl mt-10"
        >
          {t('banner_title')}
        </motion.p>
        <motion.p 
          variants={fadeInAnimation} 
          custom={3} 
          className="w-[80%] max-md:w-[90%] pt-5"
        >
          {t('banner_description')}
        </motion.p>
        <motion.div variants={fadeInAnimation} custom={4}>
          <Button className="PrimaryButton" href="#painPoints" as={Link}>
            {t('banner_button')} <Arrow />
          </Button>
        </motion.div>
      </motion.div>
      
      <div className="absolute right-[10%] max-md:right-0">
        <Towers />
      </div>
    </div>
  )
}

export default Banner;