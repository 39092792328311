const Arrow = ({ direction = 'down', size = null, color = 'white' }) => {
  let transform = '';
  let widhtHeight = size === "xl"  && { width: 50, height: 52 };

  switch (direction) {
    case 'up':
      transform = 'rotate(180deg)';
      break;
    case 'down':
      transform = 'rotate(0deg)';
      break;
    case 'left':
      transform = 'rotate(90deg)';
      break;
    case 'right':
      transform = 'rotate(-90deg)';
      break;
    default:
      transform = 'rotate(0)';
  }

  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform, ...widhtHeight }}
    >
      <path
        d="M6.75 1C6.75 0.585786 6.41421 0.25 6 0.25C5.58579 0.25 5.25 0.585786 5.25 1L6.75 1ZM5.46967 13.5303C5.76256 13.8232 6.23744 13.8232 6.53033 13.5303L11.3033 8.75736C11.5962 8.46447 11.5962 7.98959 11.3033 7.6967C11.0104 7.40381 10.5355 7.40381 10.2426 7.6967L6 11.9393L1.75736 7.6967C1.46447 7.40381 0.989592 7.40381 0.696699 7.6967C0.403806 7.98959 0.403806 8.46447 0.696699 8.75736L5.46967 13.5303ZM5.25 1L5.25 13L6.75 13L6.75 1L5.25 1Z"
        fill={color}
      />
    </svg>
  );
};

export default Arrow;