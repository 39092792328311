import { motion } from 'framer-motion';
import { useState, useEffect, useCallback } from 'react';
import { Card, CardBody, CardFooter, Avatar } from "@nextui-org/react";
import { useTranslation } from 'react-i18next';
import Arrow from "../assets/Arrow";
import Quotes from "../assets/Quotes";
import Client1 from "../assets/Betiana.webp";
// import Client2 from "";

const ReviewsCarousel = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const { t } = useTranslation();
    
    const reviews = t('reviews', { returnObjects: true });

    const reviewsLength = reviews.length;

    const nextSlide = useCallback(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % reviewsLength);
    }, [reviewsLength]);

    const prevSlide = useCallback(() => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + reviewsLength) % reviewsLength);
    }, [reviewsLength]);

    useEffect(() => {
        if (!isHovered) {
            const timer = setTimeout(nextSlide, 5000);
            return () => clearTimeout(timer);
        }
    }, [currentSlide, nextSlide, isHovered]);

    return (
        <div className="relative max-w-[350px] mx-auto mx-20">
            <div className="absolute inset-y-0 -left-10 md:-left-14 flex items-center">
                <div 
                    className="p-2 px-3.5 rounded-full hover:bg-gray-100 hover:bg-opacity-25 ease-in duration-150 cursor-pointer"
                    onClick={prevSlide}
                >
                    <button className="text-gray-500 hover:text-gray-800 md:ml-0 focus:outline-none">
                        <Arrow direction="left" />
                    </button>
                </div>
            </div>
            <div className="absolute inset-y-0 -right-10 md:-right-14 flex items-center">
                <div 
                    className="p-2 px-3.5 rounded-full hover:bg-gray-100 hover:bg-opacity-25 ease-in duration-150 cursor-pointer"
                    onClick={nextSlide}
                >
                    <button className="text-gray-500 hover:text-gray-800 md:mr-0 focus:outline-none">
                        <Arrow direction="right" />
                    </button>
                </div>
            </div>
            <div className="overflow-hidden relative -mt-16 sm:mt-0">
                <div className="flex transition-transform duration-400 ease-in-out" style={{ width: `${(reviews.length * 100) + ((reviews.length - 1) * 10)}%`, transform: `translateX(-${(currentSlide * 50)}%)` }}>
                    {reviews.map((review, index) => (
                        <motion.div 
                            key={index} 
                            className="flex-shrink-0 flex" 
                            style={{ width: `${100 / reviews.length}%` }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            <Card className="max-w-[290px] sm:max-w-[350px] rounded-3xl quoteCard hover:cursor-pointer">
                                <CardBody className="pt-12 px-10 sm:px-12 italic relative">
                                    <p className="absolute top-0 right-0 transform translate-x-[-50%] translate-y-[100%]"><Quotes /></p>
                                    <p className="text-[0.9rem] sm:text-base">{review.quote}</p>
                                </CardBody>
                                <CardFooter className="gap-3">
                                    <div className="flex justify-center gap-5 p-2 sm:p-8 sm:ml-0 ml-5">
                                        <Avatar showFallback src={require(`../assets/${review.avatar}`)} />
                                        <div className="flex flex-col gap-1 items-start justify-center mt-1 sm:-mt-0.5 sm:ml-0 -ml-2 ">
                                            <h4 className="text-[0.8rem] sm:text-sm font-bold leading-none text-default-600">{review.author}</h4>
                                            <h5 className="text-[0.8rem] sm:text-sm tracking-tight text-default-500">{review.company}</h5>
                                        </div>
                                    </div>
                                </CardFooter>
                            </Card>
                        </motion.div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ReviewsCarousel;