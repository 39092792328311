import { motion } from "framer-motion";
import ReviewsCarousel from "./ReviewsCarousel";
import { useTranslation } from "react-i18next";

const fadeInAnimation = {
  initial: { y: 50 },
  whileInView: {
    y: 0,
    transition: {
      type: "spring",
      bounce: 0.5,
      duration: 2.5
    }
  },
  viewport: { once: true, amount: 0.6 }
};

const Recommendations = () => {
  const { t } = useTranslation();

  return (
    <div className="flex max-md:flex-col justify-center items-center mx-auto max-lg:gap-5 py-[100px] sm:py-[250px]" id="recommendations">
      <motion.div 
        className="max-md:w-[80%] w-[40%] max-md:mb-[90px]"
        initial={fadeInAnimation.initial}
        whileInView={fadeInAnimation.whileInView}
        viewport={fadeInAnimation.viewport}
      >
        <p className="font-bold text-5xl">
          {t('recommendations.title')}
        </p>
        <p className="pt-5">
          {t('recommendations.subtitle')}
        </p>
      </motion.div>
      
      <div className="flex flex-col justify-center items-start max-md:items-center">
        <div className="flex justify-start gap-5">
          <ReviewsCarousel />
        </div>
      </div>
    </div>
  )
}

export default Recommendations;