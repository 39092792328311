import { Button, Image, Link } from "@nextui-org/react";
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
import Letter from "../assets/Letter";
import ClientHandShakingJpg from "../assets/ClientHandShaking.webp";

const fadeInAnimation = {
  initial: { y: 50, opacity: 0 },
  whileInView: { 
    y: 0, 
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 1.5
  }},
  viewport: { once: true, amount: 0.3 }
};

const ClientBanner = () => {
  const { t } = useTranslation();

  return (
    <motion.div 
      className="grid max-lg:grid-flow-row lg:grid-cols-2 mx-[60px] max-sm:mx-[15px] my-[200px] max-sm:my-[275px] max-lg:gap-20"
      initial={fadeInAnimation.initial}
      whileInView={fadeInAnimation.whileInView}
      viewport={fadeInAnimation.viewport}
    >
      <div className="flex justify-center max-lg:items-start items-end max-lg:w-[100%]">
        <Image
          removeWrapper
          alt="handShaking"
          className="w-auto max-h-[500px]"
          src={ClientHandShakingJpg}
        />
      </div>
      
      <div 
        className="max-lg:order-first flex flex-col justify-center items-start w-[80%] max-lg:w-[100%] -mt-6"
        initial={fadeInAnimation.initial}
        whileInView={fadeInAnimation.whileInView}
        viewport={fadeInAnimation.viewport}
      >
        <p className="text-3xl font-bold">
          {t('clientBanner.title')}
        </p>
        <Button className="h-1 PrimaryButton" href="#contactUs" as={Link}>
          <Letter /> {t('clientBanner.button')}
        </Button>
      </div>    
    </motion.div>
  );
};

export default ClientBanner;