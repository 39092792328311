import Navbar from "./components/navbar";
import Banner from './components/banner';
import PainPoints from './components/painPoints';
import Services from './components/services';
import Recommendations from "./components/recommendations";
import ClientBanner from "./components/clientBanner";
import ContactUs from "./components/contactUs";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Banner />
      <PainPoints />
      <Services />
      <Recommendations />
      <ClientBanner />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;