import React, { useRef, useEffect, useState } from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, Button } from '@nextui-org/react';
import DynamicTabs from './TypeSelector';
import { useTranslation } from 'react-i18next';

const Modals = ({ isOpen, onOpenChange, selectedService }) => {
    const contactUsRef = useRef(null);
    const [imageLoaded, setImageLoaded] = useState(false);
    const { t } = useTranslation();

    const handleContactClick = () => {
        onOpenChange(false);
        if (contactUsRef.current) {
            contactUsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        setImageLoaded(false);
    }, [isOpen]);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    useEffect(() => {
        contactUsRef.current = document.getElementById('contactUs');
    }, []);

    const renderBoldText = (text) => {
        const boldPhrases = [
            "Desarrollo de bases de datos:", "Almacenamiento de datos:", "Sistemas de análisis de datos:", "Integración de software con cámaras:", "Personalización para tus necesidades:",
            "Database Development:", "Data Storage:","Data Analysis Systems:","Software Integration with Cameras:","Customization for Your Needs:",
            "Desenvolvimento de bancos de dados:", "Armazenamento de dados:", "Sistemas de análise de dados:", "Integração de software com câmeras:", "Personalização para suas necessidades:"
        ];
    
        // Separa el texto en partes según las frases a resaltar
        const parts = text.split(
            /(Desarrollo de bases de datos:|Almacenamiento de datos:|Sistemas de análisis de datos:|Integración de software con cámaras:|Personalización para tus necesidades:|Database Development:|Data Storage:|Data Analysis Systems:|Software Integration with Cameras:|Customization for Your Needs:|Desenvolvimento de bancos de dados:|Armazenamento de dados:|Sistemas de análise de dados:|Integração de software com câmeras:|Personalização para suas necessidades:)/);
    
        return parts.map((part, index) => {
            if (boldPhrases.includes(part)) {
                return (
                    <strong key={index} className="font-semibold">
                        {part}
                    </strong>
                );
            }
            return <span key={index}>{part}</span>;
        });
    };    

    const getInnerDescriptions = () => {
        let innerDescriptions = [];
    
        let count = 3;
        if (selectedService.key === 'service1' || selectedService.key === 'service2') {
            count = 1;
        } else if (selectedService.key === 'service3') {
            count = 3;
        } else if (selectedService.key === 'service4') {
            count = 2;
        }
    
        // Iterar sobre las posibles inner_descriptions (serviceX_inner_descriptionX)
        for (let i = 1; i <= count; i++) {
            const key = `${selectedService.key}_inner_description${i}`;
            const description = t(key);
    
            let formattedDescription = description;
            if (i === 1 && (selectedService.key === 'service1' || selectedService.key === 'service2' || selectedService.key === 'service3')) {
                formattedDescription = ` \u2022 ${description}`;
            }
    
            if (description) {
                innerDescriptions.push(
                    <div key={key} className='mb-2'>
                        {renderBoldText(formattedDescription)}
                    </div>
                );
            }
        }
    
        return innerDescriptions;
    };      

    return (
        <Modal
            className='fixed bg-neutral-900'
            onOpenChange={onOpenChange}
            backdrop="opaque"
            isOpen={isOpen}
            size="4xl"
            unmountOnExit
        >
            <ModalContent>
                <ModalHeader className='flex items-center justify-center text-white sm:text-4xl font-bold sm:my-3'>
                    {t(`${selectedService.key}_title`)}
                </ModalHeader>
                <div style={{ maxHeight: 'calc(100vh - 200px)', scrollbarColor: '#666 #333' }} className='no-scrollbar overflow-auto'>
                    <ModalBody className='p-0 flex flex-col items-center mb-4'>
                        <div className={`image-container ${imageLoaded ? 'fade-in' : ''}`}>
                            <img
                                className='w-full rounded-none mb-10'
                                src={selectedService.banner}
                                alt={`Service-${selectedService.key}`}
                                onLoad={handleImageLoad}
                                style={{ display: imageLoaded ? 'block' : 'none' }}
                            />
                            {!imageLoaded && (
                                <div className="loading-placeholder w-full rounded-none mb-10" style={{ height: '500px', backgroundColor: '#ccc' }}>
                                    {/* Placeholder for loading animation or skeleton */}
                                </div>
                            )}
                        </div>
                        <div className='text-white text-left mx-10 sm:mx-16 mb-10'>
                            <h1 className='text-2xl font-bold mb-8 text-slate-300'>{t(`${selectedService.key}_subtitle`)}</h1>
                            {getInnerDescriptions()}
                        </div>
                        {/*<DynamicTabs />
                        <Button
                            radius="full"
                            variant="ghost"
                            color="white"
                            className="bg-black text-white -mt-[35px] sm:-mt-[84px]"
                            onClick={handleContactClick}
                        >
                            {t('contact_us')}
                        </Button>*/}
                    </ModalBody>
                </div>
            </ModalContent>
        </Modal>
    );
};

export default Modals;