import React from 'react';
import { useTranslation } from 'react-i18next';

const PainPoints = () => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col justify-center items-center mt-[100px] mb-[320px]" id="painPoints">
		<p className="text-center text-5xl font-bold pb-8">{t('pain_points_title')}</p>

		<p className="w-[80vw] text-center pb-32">
			{t('pain_points_description')}
		</p>

		<div className="flex max-lg:flex-col justify-around gap-20 items-center">
			<div className="painPointsStatistic">
			<h2 className="statisticNumber">{t('statistic1_title')}</h2>
			<p>{t('statistic1_description')}</p>
			</div>

			<div className="painPointsStatistic">
			<h2 className="statisticNumber">{t('statistic2_title')}</h2>
			<p>{t('statistic2_description')}</p>
			</div>

			<div className="painPointsStatistic">
			<h2 className="statisticNumber">{t('statistic3_title')}</h2>
			<p>{t('statistic3_description')}</p>
			</div>
		</div>
		</div>
	);
}

export default PainPoints;