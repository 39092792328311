import React from "react";
import { motion } from "framer-motion";
import { Card, Image, Tabs, Tab, CardBody } from "@nextui-org/react";
import PD1 from '../assets/type/PDImage1.webp';
import PD2 from "../assets/type/HALFImage2.webp";
import HALF1 from "../assets/type/HALFImage1.webp";
import HALF2 from '../assets/type/PDImage2.webp';
import CUSTOM1 from "../assets/type/CUSTOMImage1.webp";
import CUSTOM2 from "../assets/type/CUSTOMImage2.webp";
import { useTranslation } from 'react-i18next';

const transition = { duration: 0.85, ease: "easeIn" };

const DynamicTabs = () => {
    const [selected, setSelected] = React.useState("pre-disenado");
    const { t } = useTranslation();

    const tabs = [
        {
            id: "pre_disenado",
            label: t('tabs.pre_disenado.label'),
            title: t('tabs.pre_disenado.title'),
            content: t('tabs.pre_disenado.content'),
            image1: PD1,
            image2: PD2
        },
        {
            id: "5050",
            label: t('tabs.5050.label'),
            title: t('tabs.5050.title'),
            content: t('tabs.5050.content'),
            image1: HALF1,
            image2: HALF2
        },
        {
            id: "personalizado",
            label: t('tabs.personalizado.label'),
            title: t('tabs.personalizado.title'),
            content: t('tabs.personalizado.content'),
            image1: CUSTOM1,
            image2: CUSTOM2
        }
    ];

    return (
        <Card className="mx-2 sm:mx-20 p-4 bg-black rounded-[25px] sm:mb-10">
            <Tabs className="justify-center mt-2 sm:mt-6" radius="full" variant="bordered" items={tabs} selectedKey={selected} onSelectionChange={setSelected}>
                {tabs.map((tab) => (
                    <Tab key={tab.id} title={tab.label}>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={transition}
                        >
                            <CardBody className="h-[325px] sm:h-[350px] overflow-visible flex gap-4 text-white relative mb-6 py-4">
                                <div className="first-container pt-4 pb-10">
                                    <Image src={tab.image1} alt="Service1PNG" className="w-4/12 sm:w-1/3 float-right" />
                                    <h1 className="text-balance text-xl sm:text-2xl font-semibold tracking-wide mb-4 pr-36 sm:pr-0">{tab.title}</h1>
                                </div>
                                <div className="second-container absolute right-0 top-18">
                                    <Image src={tab.image2} alt="Service2PNG" className="w-5/12 float-right ml-3 sm:ml-6 mt-36 mr-[5rem] sm:mr-[7rem] mb-4" />
                                    <p className="text-pretty mt-36 mr-20 mb-10 ml-3 text-sm sm:text-base ">{tab.content}</p>
                                </div>
                            </CardBody>
                        </motion.div>
                    </Tab>
                ))}
            </Tabs>
        </Card>
    );
};

export default DynamicTabs;