import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { useDisclosure } from '@nextui-org/react';
import { Image } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import Modals from './Modals';
import Arrow from "../assets/Arrow";
import Service1PNG from '../assets/services/service1.webp';
import Service2PNG from '../assets/services/service2.webp';
import Service3PNG from '../assets/services/service3.webp';
import Service4PNG from '../assets/services/service4.webp';
import Service12PNG from '../assets/services/service1.2.webp';
import Service22PNG from '../assets/services/service2.2.webp';
import Service32PNG from '../assets/services/service3.2.webp';
import Service42PNG from '../assets/services/service4.2.webp';

const services = [
    { photo: Service1PNG, banner: Service12PNG, key: 'service1' },
    { photo: Service2PNG, banner: Service22PNG, key: 'service2' },
    { photo: Service3PNG, banner: Service32PNG, key: 'service3' },
    { photo: Service4PNG, banner: Service42PNG, key: 'service4' },
];

const fadeInAnimation = {
    initial: { y: 50, opacity: 0 },
    whileInView: {
        y: 0,
        opacity: 1,
        transition: {
            type: "spring",
            bounce: 0.2,
            duration: 2
        }
    },
    viewport: { once: true, amount: 0.5 }
};

const cardVariants = {
    hover: {
        translateY: -10,
        transition: { duration: 0.3 }
    },
    initial: {
        translateY: 0,
        transition: { duration: 0.2 }
    }
};

const ServiceCards = () => {
    const { t } = useTranslation();
    const [selectedService, setSelectedService] = useState(services[0]);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const { isOpen, onOpen, onOpenChange } = useDisclosure();

    useEffect(() => {
        const modalContainer = document.getElementById('modal-container');
        if (modalContainer) {
            modalContainer.setAttribute('tabindex', '-1');
        }
    }, [isOpen]);

    const handleHover = (index) => setHoveredIndex(index);
    const handleHoverLeave = () => setHoveredIndex(null);

    const getImageMarginTop = (key) => {
        const title = t(`${key}_title`);
        if (title.includes('DADOS') || title.includes('SEGURANÇA')) {
            return 'mt-[5.2rem] md:-mt-5';
        }
        return 'mt-16 md:-mt-5';
    };

    const getParagraphMarginTop = (key) => {
        const title = t(`${key}_title`);
        if (title.includes('DADOS') || title.includes('SEGURANÇA')) {
            return 'mt-0 md:mt-0'; // Ajusta el margen superior para dispositivos móviles y medianos
        }
        return 'mt-2 md:mt-0'; // Valor predeterminado para otros casos
    };

    return (
        <>
            {services.map((item, index) => (
                <motion.div
                    key={index}
                    initial={fadeInAnimation.initial}
                    whileInView={fadeInAnimation.whileInView}
                    viewport={fadeInAnimation.viewport}
                    variants={cardVariants}
                    animate={hoveredIndex === index ? "hover" : "initial"}
                    className={`ServiceCard-container flex flex-col items-center bg-neutral-900 rounded-lg shadow-md
                                w-[340px] h-[245px] mt-3 mb-4
                                md:min-w-[450px] md:w-4/5 md:h-[300px] sm:mt-16 sm:ml-20
                                md:flex-row
                                ${hoveredIndex === index ? 'border-hover' : ''}
                                hover:cursor-pointer md:hover:cursor-pointer`}
                    onMouseEnter={() => handleHover(index)}
                    onMouseLeave={handleHoverLeave}
                    onClick={() => {
                        setSelectedService(services[index]);
                        onOpen();
                    }}
                >
                    <div className="Image-container flex items-center justify-center mr-16 sm:mr-0 -ml-7 sm:-ml-14">
                        <div style={{ width: '200px', height: '200px' }}>
                            <Image
                                className={`rounded-md w-3/5 sm:w-full sm:h-full object-cover ${getImageMarginTop(item.key)}`}
                                src={item.photo}
                                alt={`Service-${index}`}
                            />
                        </div>
                    </div>
                    <div className="Text-container flex flex-col justify-between p-4 -mt-44 sm:mt-0 leading-normal">
                        <h5 style={{ fontSize: 'clamp(24px, 1.5vw + 0.5rem, 35px)' }}
                            className="sm:mt-0 sm:mr-4 sm:ml-0
                        -mt-5 mb-2 ml-2 text-[24px] font-bold tracking-tight leading-7 md:leading-10">
                            {renderColoredTitle(t(`${item.key}_title`))}
                        </h5>
                        <p style={{ fontSize: 'clamp(12.75px, 0.5vw + 0.5rem, 16px)' }}
                            className={`text-gray-500 text-pretty ${getParagraphMarginTop(item.key)} mr-1 mb-3 ml-36 sm:mt-0 sm:mr-7 sm:ml-0`}>
                            {t(`${item.key}_description`)}
                        </p>
                        <div className="ml-36 sm:ml-1">
                            <Arrow direction="right" color="white" />
                        </div>
                    </div>
                </motion.div>
            ))}
            <Modals
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                selectedService={selectedService}
                services={services}
            />
        </>
    );
};

const renderColoredTitle = (title) => {
    const words = title.split(' ');
    return words.map((word, index) => {
        if (word === 'Desarrollo' || word === 'de' || word === 'en' || word === 'Development' || word === 'Desenvolvimento' || word === 'em') {
            return (
                <span key={index} style={{ color: 'gray' }}>
                    {word}{' '}
                </span>
            );
        } else if (word === word.toUpperCase()) {
            return (
                <span key={index} style={{ color: 'white' }}>
                    {word}{' '}
                </span>
            );
        }
        return <span key={index}>{word} </span>;
    });
};

export default ServiceCards;