import React from 'react';
import { useTranslation } from 'react-i18next';
import ServiceCards from './ServicesCards';

const Services = () => {
  const { t } = useTranslation();

  return (
    <div className="
      mx-[0px] max-sm:mx-[15px]
      my-[75px] max-sm:my-[40px]
      py-[32px] max-sm:py[10px]"
      id="services"
    >
      <p className="max-sm:text-6xl text-7xl font-extrabold text-center mb-12 sm:mb-6">
        {t('services_title')}
      </p>

      <div
        className="md:min-w-4/6 w-4/6 mx-auto 
        grid lg:grid-cols-2
        justify-center 
        lg:gap-3
        mb-5
        "
      >
        <ServiceCards />
        <br />
      </div>
    </div>
  );
};

export default Services;