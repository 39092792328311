import React from 'react';
import { useState, useEffect } from "react";
import { Navbar as Nav, NavbarBrand, NavbarContent, NavbarItem, Link, Button, NavbarMenuToggle, NavbarMenuItem, NavbarMenu } from "@nextui-org/react";
import { motion, useScroll } from "framer-motion";
import TowerSoftwareLogo from '../assets/TowerSoftwareLogo';
import LanguageSwitch from "../components/LanguageSwitch";
import { useTranslation } from 'react-i18next';

const menuItems = [
  { name: "menu_home", href: "#home"  }, 
  { name: "menu_services", href: "#services" }, 
  { name: "menu_about_us", href: "#recommendations" },
  { name: "menu_contact", href: "#contactUs" }
];

const variants = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: -25 }
};

const NavBar = () => {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { scrollY } = useScroll();
  const [hidden, setHidden] = useState(false);
  const [isTop, setIsTop] = useState(true);

  useEffect(() => {
    return scrollY.onChange(() => update());
  });

  const update = () => {
    if (scrollY?.current < scrollY?.prev) {
      setHidden(false);
    } else if (scrollY?.current > 100 && scrollY?.current > scrollY?.prev) {
      setHidden(true);
    }
    if (scrollY?.current > 280) {
      setIsTop(false);
    } else {
      setIsTop(true);
    }
  };

  const NavBarMenuItems = () => (
    menuItems.map((item, index) => (
      <NavbarMenuItem key={`${item}-${index}`} >
        <Link
          className="px-[40px] max-sm:px-[0px] w-full text-white"
          href={item.href}
          size="lg"
          onClick={() => setIsMenuOpen(false)}
        >
          {t(item.name)}
        </Link>
      </NavbarMenuItem>
    ))
  );
  
  return (
    <motion.div 
      variants={variants}
      animate={hidden ? "hidden" : "visible"}
      transition={{ ease: [0.1, 0.25, 0.3, 0.3], duration: 0.2 }}
      className="fixed w-full z-30"
    >
      <Nav 
        className="navBar h-[120px] px-[60px] max-sm:px-[10px] bg-transparent" 
        onMenuOpenChange={setIsMenuOpen} 
        isMenuOpen={isMenuOpen}
        isBlurred={!isTop || isMenuOpen}
      >
        <NavbarContent>
          <NavbarMenuToggle 
            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
            className="md:hidden"
          />
          <NavbarBrand className="flex items-center space-x-4">
            <TowerSoftwareLogo />
            <LanguageSwitch />
          </NavbarBrand>
        </NavbarContent>

        <NavbarContent justify="center" className="hidden md:flex gap-8">
          <NavbarItem>
            <Link className="navBarItem" href="#home">
              {t('menu_home')}
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link className="navBarItem" href="#services">
              {t('menu_services')}
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link className="navBarItem" href="#recommendations">
              {t('menu_about_us')}
            </Link>
          </NavbarItem>
        </NavbarContent>
        
        <NavbarContent justify="end">
          <NavbarItem>
            <Button className="navBarItemContact max-sm:w-[120px] max-sm:h-[37px] hidden sm:inline-flex" as={Link} href="#contactUs" >
              {t('menu_contact')}
            </Button>
          </NavbarItem>
        </NavbarContent>

        <NavbarMenu className="top-[120px] bg-transparent transition">
          <NavBarMenuItems />
        </NavbarMenu>

      </Nav>
    </motion.div>
  );
};

export default NavBar;