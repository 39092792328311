import React, { useState, useRef } from 'react';
import { Button, Input, Textarea, Spinner } from "@nextui-org/react";
import { EMAIL_SERVICE_ID, EMAIL_SERVICE_TEMPLATE, EMAIL_SERVICE_PUBLIC_KEY } from '../config/appConfig';
import Arrow from "../assets/Arrow";
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

const fadeInAnimation = {
  initial: { y: 50, opacity: 0 },
  whileInView: { 
    y: 0, 
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 2
  }},
  viewport: { once: true, amount: 0.4 }
};

const ContactUs = () => {
  const { t } = useTranslation();
  const [recaptcha, setRecaptcha] = useState(null);
  const refCaptcha = useRef();

  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [errors, setErrors] = useState({});
  
  const [loading, setLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showFailureMessage, setShowFailureMessage] = useState(false);

  const isFormValid = () => {
    let tempErrors = {};
    let isValid = true;

    if (fullname.length <= 3) {
      tempErrors["fullname"] = true;
      isValid = false;
    }

    if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)) {
      tempErrors["email"] = true;
      isValid = false;
    }

    if (message.length <= 20) {
      tempErrors["message"] = true;
      isValid = false;
    }

    if (recaptcha === null) {
      tempErrors["recaptcha"] = true;
      isValid = false;
    }

    setErrors({ ...tempErrors });
    console.log({ tempErrors });
    return isValid;
  };

  const onFinish = () => {
    if (!isFormValid()) {
      return;
    }

    setLoading(true);
    
    const sendMessage = async () => {
      try {
        const params = {
          'g-recaptcha-response': recaptcha,
          from_name: fullname,
          message: 
          `
            ${message} 
            
            EMAIL: ${email}
          `,
        }

        await emailjs.send(EMAIL_SERVICE_ID, EMAIL_SERVICE_TEMPLATE, params, EMAIL_SERVICE_PUBLIC_KEY);    
        refCaptcha.current.reset();
        setRecaptcha(null);
        console.log({ params, fullname, email, message });

        setShowSuccessMessage(true);
        setShowFailureMessage(false);
      } catch (_) {
        setShowSuccessMessage(false);
        setShowFailureMessage(true);
      } finally {
        setLoading(false);
      }
    }
        
    sendMessage();
  }

  return (
    <motion.div 
      className="
        grid gap-2 
        max-lg:grid-flow-row 
        xl:grid-cols-2 
        mx-[200px] max-sm:mx-[15px]
        my-[120px] max-sm:my-[0px]
        pb-[60px]"
      initial={fadeInAnimation.initial}
      whileInView={fadeInAnimation.whileInView}
      viewport={fadeInAnimation.viewport}
      id="contactUs"
    >
      <div className="flex justify-center max-lg:items-start items-center">
        <div>
          <p className="font-extrabold max-sm:text-4xl text-7xl">
            {t('contactUs.title')}
          </p>
          <div className="w-[46px] h-[4px] my-5 bg-[#636363]" />
          <p className="max-sm:text-md text-md">
            {t('contactUs.subtitle')}
          </p>
        </div>
      </div>   

      <div className="flex flex-col justify-center items-center">
          {loading && <Spinner label="Enviando Mensaje..." color="current" labelColor="success" className="my-4" />}
          <form className={`contactUsForm flex flex-col items-start mt-8
            xl:w-[75%]
            md:w-[500px] 
            w-[100%] max-md:max-w-[500px]
            ${(showFailureMessage || showSuccessMessage || loading) && "hidden"}`}
          > 
            <Input 
              type="text" 
              label={t('contactUs.nameLabel')} 
              variant="underlined" 
              className="mt-2"
              style={{ color: '#fff' }}
              onChange={(e) => setFullname(e.target.value)} 
              errorMessage={errors.fullname && t('contactUs.nameError')}
            />
            <Input 
              type="email" 
              label={t('contactUs.emailLabel')} 
              variant="underlined" 
              className="mt-2"
              style={{ color: '#fff' }}
              onChange={(e) => setEmail(e.target.value)} 
              errorMessage={errors.email && t('contactUs.emailError')}
            />
            <Textarea 
              label={t('contactUs.messageLabel')} 
              variant="underlined" 
              className="mt-6 mb-7"
              style={{ color: '#fff', height: '110px' }}
              onChange={(e) => setMessage(e.target.value)} 
              errorMessage={errors.message && t('contactUs.messageError')}
            />

            <ReCAPTCHA
                ref={refCaptcha}
                sitekey="6LcIsvQnAAAAAEYMdTiAxzTIwsMDu0H9bDWQwSA8"
                onChange={token => setRecaptcha(token)}
                theme="dark"
            />
            {errors.recaptcha && <span className="text-tiny text-red-500">{t('contactUs.recaptchaError')}</span>}
            
            <Button className="PrimaryButton w-[110px] flex justify-between" onClick={() => onFinish()}>
              {t('contactUs.sendButton')} <Arrow direction='right' />
            </Button>
          </form>

          {showSuccessMessage && <p className="text-green-500 font-semibold my-4">{t('contactUs.successMessage')}</p>}
          {showFailureMessage && <p className="text-red-500 font-semibold my-4">{t('contactUs.failureMessage')}</p>}
      </div>   
    </motion.div>
  );
};

export default ContactUs;