import { motion } from "framer-motion";

const Towers = () => {
  return (
    <svg 
      width="430" 
      height="100%" 
      viewBox="0 0 280 420" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className="max-sm:w-[80vw]"
    >
      <motion.g 
        initial={{ opacity: 0.6, y: 250 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 2 }}
        filter="url(#filter0_i_190_94)"
      >
        <path d="M154.48 -6.25248e-06L226 51.9882L226 420L154 420L154.48 -6.25248e-06Z" fill="#343434" />
        <path d="M154 3.05176e-05L80 51.9882L80 420L153.51 420L154 3.05176e-05Z" fill="#111111" />
      </motion.g>
      <motion.g 
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5 }}
        filter="url(#filter1_i_190_94)"
      >        
        <path d="M-0.0001081 313.198L46.192 327.939L92.384 313.198L46.192 298.457L-0.0001081 313.198Z" fill="#666666" />
        <path d="M46.192 297.225L92.384 312.148L92.384 419.788L46.192 419.788L46.192 297.225Z" fill="#343434" />
        <path d="M46.1921 297.225L7.06984e-05 312.148L8.01086e-05 419.788L46.1921 419.788L46.1921 297.225Z" fill="#111111" />
      </motion.g>
      <motion.g 
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 2 }}
        filter="url(#filter2_i_190_94)"
      >
        <path d="M217.411 354.503L248.205 376.059L279 354.503L248.205 332.947L217.411 354.503Z" fill="#666666" />
        <path d="M248 333L279 354.75L279 420L248 420L248 333Z" fill="#343434" />
        <path d="M248 333L217 354.75L217 420L248 420L248 333Z" fill="#111111" />
      </motion.g>
      <motion.g 
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.9 }}
        filter="url(#filter3_i_190_94)"
      >
        <path d="M168.139 343.813L198.934 378.347L217.411 357.627L186.616 323.093L168.139 343.813Z" fill="#666666" />
        <path d="M186.616 323.093L218.642 347.957L218.642 419.788L186.616 419.788L186.616 323.093Z" fill="#343434" />
        <path d="M187 322L168 334.095L168 420L187 420L187 322Z" fill="#111111" />
      </motion.g>
      <defs>
        <filter id="filter0_i_190_94" x="80" y="0" width="157" height="434" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="11" dy="14" />
          <feGaussianBlur stdDeviation="20.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_190_94" />
        </filter>
        <filter id="filter1_i_190_94" x="0" y="297.225" width="103.384" height="136.563" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="11" dy="14" />
          <feGaussianBlur stdDeviation="20.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_190_94" />
        </filter>
        <filter id="filter2_i_190_94" x="217" y="332.947" width="73" height="101.053" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="11" dy="14" />
          <feGaussianBlur stdDeviation="20.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_190_94" />
        </filter>
        <filter id="filter3_i_190_94" x="168" y="322" width="61.6423" height="112" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="11" dy="14" />
          <feGaussianBlur stdDeviation="20.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_190_94" />
        </filter>
      </defs>
    </svg>

  );
};

export default Towers;