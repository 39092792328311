import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button } from "@nextui-org/react";
import { motion } from 'framer-motion';
import MxFlag from "../assets/MxFlag";
import EuFlag from "../assets/EuFlag";
import BrFlag from "../assets/BrFlag";
import GlobeMap from "../assets/GlobeMap";
import Bookmark from '../assets/Bookmark';

const fadeInAnimation = {
    initial: { opacity: 0 },
    animate: { 
        opacity: 1,
        transition: {
            duration: 1
        }
    }
};

const LanguageSwitcher = () => {
    const { i18n, t } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [showLanguageSwitcher, setShowLanguageSwitcher] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => setShowLanguageSwitcher(true), 500);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (initialLoad) {
            setSelectedLanguage(null); // Ensure "Language" is shown initially
            setInitialLoad(false);
        } else {
            setSelectedLanguage(i18n.language); // Set language after initial load
        }
    }, [initialLoad, i18n.language]);

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
        i18n.changeLanguage(language);
    };

    const getFlag = (language) => {
        switch (language) {
            case "es":
                return <MxFlag />;
            case "en":
                return <EuFlag />;
            case "pt":
                return <BrFlag />;
            default:
                return <GlobeMap />;
        }
    };

    return showLanguageSwitcher && (
        <motion.div
            className="w-full justify-right"
            initial={fadeInAnimation.initial}
            animate={fadeInAnimation.animate}
        >
            <Dropdown placement="bottom-start" backdrop="blur" className="bg-zinc-800">
                <DropdownTrigger>
                    <Button className="bg-neutral-700 text-gray-200 font-LtSoul font-semibold text-base py-2 px-4" variant="solid" radius="full">
                        {selectedLanguage ? (
                            <>
                                {getFlag(selectedLanguage)} {t('language')} <Bookmark />
                            </>
                        ) : (
                            <>
                                <GlobeMap /> Language <Bookmark />
                            </>
                        )}
                    </Button>
                </DropdownTrigger>
                <DropdownMenu
                    className="text-gray-200"
                    disallowEmptySelection
                    selectionMode="single"
                    selectedKeys={new Set([selectedLanguage])}
                    onSelectionChange={(keys) => handleLanguageChange([...keys][0])}
                >
                    <DropdownItem key="es" isDisabled={selectedLanguage === "es"} startContent={<MxFlag />}>
                        Español
                    </DropdownItem>
                    <DropdownItem key="en" isDisabled={selectedLanguage === "en"} startContent={<EuFlag />}>
                        English
                    </DropdownItem>
                    <DropdownItem key="pt" isDisabled={selectedLanguage === "pt"} startContent={<BrFlag />}>
                        Português
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </motion.div>
    );
};

export default LanguageSwitcher;